/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

// Use icons from our library
import '../utils/fontawesome'
import { ButtonLink, ButtonLinkSimp } from '../components/Button'
import { icon } from "@fortawesome/fontawesome-svg-core"

const Layout = props => {
  const { title, children } = props
  const [toggleNav, setToggleNav] = React.useState(false)

  const data = useStaticQuery(graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`)
  
  return (
    <div className={`site-wrapper site-head-open`} style={{align: 'center'}}>
      <header className="site-head">
        <div id="menu" className="site-head-container">
          <a
            className="nav-burger"
            href={`#menu`}
            onClick={() => setToggleNav(!toggleNav)}
          >
          </a>
          <nav id="swup" className="site-head-left">
            <ul className="nav">
              <li id="navbar-home" className="nav-home">
                <Link to={`/`}>Home</Link>
              </li>
              <li id="navbar-production" className="nav-about">
                <Link to={`/production`}>Production</Link>
              </li>
              <li className="nav-elements">
                <Link to={`/curriculum-vitae`}>CV</Link>
              </li>
              <li className="nav-elements">
                <Link to={`/contact`}>Contact</Link>
              </li>
            </ul>
          </nav>
          <div className="site-head-center">
            <Link className="site-head-logo" to={`/`}>
              {data.site.siteMetadata.title}
            </Link>
          </div>
          <nav className="site-head-right">
          <ul className="nav">
              <li className="nav-icon">
              <ButtonLink to={`https://twitter.com/raf_menezes`}
                icon={'twitter'} />
              </li>
              <li className="nav-icon">
              <ButtonLink to={`https://orcid.org/0000-0003-2066-3293`}
                icon={'orcid'} />
              </li>
              <li className="nav-icon">
              <ButtonLink to={`https://www.researchgate.net/profile/Rafael_Menezes8`}
                icon={'researchgate'} />
              </li>
              <li className="nav-icon">
                <ButtonLink to={`https://scholar.google.com/citations?user=FdiqBTgAAAAJ`}
                icon={'google'} />
              </li>
              <li className="nav-icon">
                <ButtonLink to={`https://github.com/r-menezes`}
                icon={'github'} />
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>
      <div className="site-foot">
        &copy; {new Date().getFullYear()} <Link to={`/`}>{data.site.siteMetadata.author}</Link> &mdash;
        Built with{" "} <Link to={`/refs`}> awesome help! </Link>
      </div>
    </div>
  )
}

export default Layout

// import React from "react"
// import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

// import Header from "./header"
// import "./layout.css"

// const Layout = ({ children }) => {


//   return (
//     <>
//       <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
//       <div
//         style={{
//           margin: `0 auto`,
//           maxWidth: 960,
//           padding: `0 1.0875rem 1.45rem`,
//         }}
//       >
//         <main>{children}</main>
//         <footer style={{
//           marginTop: `2rem`
//         }}>
//           © {new Date().getFullYear()}, Built with
//           {` `}
//           <a href="https://www.gatsbyjs.com">Gatsby</a>
//         </footer>
//       </div>
//     </>
//   )
// }

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

// export default Layout
